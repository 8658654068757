<template>

  <div class="pb" v-if="diary_data">
     
    <h1>
      {{ $t('create_diary_view_title') }}
    </h1>
 

    <DiaryEditProgress
      :steps="progress_steps"
      :states="progress_states"
      />

    <h3>
      {{ $t('create_diary_view_diary_name_title') }} {{ useAuth().getName() }}
    </h3>
 
    <p>
      {{ $t('create_diary_view_diary_name_subtitle') }}
    </p>

    <div class="head_diary">

      <div class="inf">
        
        <div>
          <label for="">{{ $t('create_diary_view_diary_name') }}</label>
        </div>

        <input type="text" v-model="diary_data.name">

        <div class="warn">
          {{ $t('create_diary_view_diary_only_single_strain') }}
        </div>
      </div>

      <div v-if="diary_data.id && diary_data.id != 'new'" class="ava">
        <img class="img" data-not-lazy :src="diary_data.avatar_small" alt="Cover">
        <DiaryEditUploadCover            
          :diary_id="diary_data.id"     
          @changed="changedCover"
          @uploaded="uploadedCover"
          />
      </div>

    </div>



    <!-- strains -->

    <h3>
      {{ $t('create_diary_view_strains_title') }}
    </h3>

    <p>
      {{ $t('create_diary_view_strains_subtitle') }}
    </p>

    <div class="items">

      <template
        v-for="(item, item_key) in diary_data.items_seed"
        >            
        <DiaryEditSeedViewBox                   
          :item="item"    
          v-if="!item.is_remove"      
          @remove="removeSeed(item_key)"
          />
      </template>

      <DiaryEditSeedSelectBox  
        @choose="chooseSeed" 
        />

    </div>

    <!-- room -->
    
    <h3>
      {{ $t('universal_room_type_title') }}
    </h3>
 
    
    <DiaryEditSelectRadioBox
      @choose="chooseRoom"
      :list="$constants.typeRoom"
      :selected="diary_data.type_room"
      />
 


    <!-- tent -->

    <template v-if="diary_data.type_room == 1">

      <h3>
        {{ $t('universal_tent_title') }}
      </h3>

 
      <div class="items">

        <template
          v-for="(item, item_key) in diary_data.items_tent"
          >            
          <DiaryEditTentViewBox                   
            :item="item"
            v-if="!item.is_remove"    
            @remove="removeTent(item_key)"
            />
        </template>

        <DiaryEditTentSelectBox  
          @choose="chooseTent" 
          />

      </div>
      
    </template>


    <!-- watering -->
    
    <h3>
      {{ $t('universal_watering_title') }}
    </h3>
     
    <DiaryEditSelectRadioBox
      @choose="chooseWareting"
      :list="$constants.typeWatering"
      :selected="diary_data.type_watering"
      />

    <!-- veg lamp -->
    
    <template v-if="diary_data.type_room != 2">

      <h3>
        {{ $t('universal_vegetation_lights_title') }}
      </h3>
  


      <div class="items">

        <template
          v-for="(item, item_key) in diary_data.items_lamp_veg"
          >            
          <DiaryEditLampViewBox                   
            :item="item"
            v-if="!item.is_remove"    
            @remove="removeLampVeg(item_key)"
            />
        </template>

        <DiaryEditLampSelectBox  
          @choose="chooseLampVeg" 
          />

      </div>

 
      
    </template>

    <!-- flo lamp -->
    
    <template v-if="diary_data.type_room != 2">

      <h3>
        {{ $t('universal_flowering_lights_title') }}
      </h3>

      <div class="clone">
        <span class="clone_btn" @click="cloneLampFromVeg()">
          <i class="icon-clone"></i> {{ $t('create_diary_lamp_clone_vegetation') }}
        </span>
      </div>
  

      <div class="items">

        <template
          v-for="(item, item_key) in diary_data.items_lamp_flo"
          >            
          <DiaryEditLampViewBox                   
            :item="item"
            v-if="!item.is_remove"    
            @remove="removeLampFlo(item_key)"
            />
        </template>

        <DiaryEditLampSelectBox  
          @choose="chooseLampFlo" 
          />

      </div>

 
      
    </template>

    <!-- medium -->
    
    <h3>
      {{ $t('universal_grow_medium_title') }}

      <span class="medium_used">
        {{ $t('create_diary_grow_medium_used') }}
        {{ total_medium }}%
      </span>
    </h3>


    <div class="items">

      <template
        v-for="(item, item_key) in diary_data.items_medium"
        >            
        <DiaryEditMediumViewBox                   
          :item="item"
          :total="total_medium"
          v-if="!item.is_remove"    
          @remove="removeMedium(item_key)"
          />
      </template>

      <DiaryEditMediumSelectBox  
        @choose="chooseMedium"
        />

    </div>

        

    <!-- technics -->
    
    <h3>
      {{ $t('universal_grow_techniques_title') }} 
    </h3>
        

    <p>
      {{ $t('create_diary_grow_techniques_moved_to_weeks') }} 
    </p>


    <UiButtons>
      <UiButton type="primary" :is-loading="isLoadingSave" :name="$t('universal_button_save')" @click="save"/>      
      <UiButton v-if="diary_data.id" :name="$t('universal_button_cancel')" @click="cancel"/>              
    </UiButtons>
      

  </div>
</template>

<script setup>

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const {$api, $tagsUtil, $popup, $ga} = useNuxtApp()
const isLoadingSave = ref(false);
var id = ref(route.params['id']);

var def = ref({
    id: id.value,
    name: null,
    items_seed: [],
    items_tent: [],
    items_lamp_veg: [],
    items_lamp_flo: [],
    items_medium: [],
    type_room: null,
    type_watering: null,
});

var progress_steps = [
  {
    id: 'name',
    name: t('create_diary_view_navigation_dairy_name'),
    hint: t('create_diary_view_navigation_give_it'),
  },
  {
    id: 'strains',
    name: t('create_diary_view_navigation_strains'),
    hint: t('create_diary_view_navigation_what_strains'),
  },
  {
    id: 'setup',
    name: t('create_diary_view_navigation_setup'),
    hint: t('create_diary_view_navigation_lets_talk_about'),
  },
];

 

const progress_states = computed(() => {
  var ret = {
    name: 'bad',
    strains: 'bad',
    setup: 'bad',
  };

  // name
  if(diary_data.value.name)         
    ret.name = 'good';

  // strains
  if(diary_data.value.items_seed && diary_data.value.items_seed.length)        
    ret.strains = 'good';

  // setup
  var setup = 0;
  if(diary_data.value.type_room)
    setup++;
  if(diary_data.value.items_medium && diary_data.value.items_medium.length)
    setup++;
  if(diary_data.value.items_lamp_veg)
    setup++;
  if(diary_data.value.items_lamp_flo)
    setup++;

  if(setup < 1)
    ret.setup = 'bad';
  else if(setup == (diary_data.value.type_room == 2 ? 2 : 4))
    ret.setup = 'normal';
  else 
    ret.setup = 'good';


  return ret;
});

const total_medium = computed(() => {
  var total = 0;
  if(!diary_data.value.items_medium)
    return total;
  for(var i of diary_data.value.items_medium){
    total += Number(i.value);
  }
  return total;
});


const removeMedium = function(item_key){      
  // diary_data.value.items_medium.splice(item_key,1);      
  diary_data.value.items_medium[item_key].is_remove = 1     
}

const chooseMedium = function(item){      
  diary_data.value.items_medium.push(item);      
}

const cloneLampFromVeg = function(){      
  diary_data.value.items_lamp_flo.push(...diary_data.value.items_lamp_veg);
}

const removeLampVeg = function(item_key){      
  // diary_data.value.items_lamp_veg.splice(item_key,1);       
  diary_data.value.items_lamp_veg[item_key].is_remove = 1  
}

const chooseLampVeg = function(item){      
  diary_data.value.items_lamp_veg.push(item);      
}

const removeLampFlo = function(item_key){      
  // diary_data.value.items_lamp_flo.splice(item_key,1);       
  diary_data.value.items_lamp_flo[item_key].is_remove = 1  
}

const chooseLampFlo = function(item){      
  diary_data.value.items_lamp_flo.push(item);      
}

const removeTent = function(item_key){      
  // diary_data.value.items_tent.splice(item_key,1);         
  diary_data.value.items_tent[item_key].is_remove = 1
}

const chooseTent = function(item){      
  diary_data.value.items_tent.push(item);      
}

const removeSeed = function(item_key){    
  // if(diary_data.value.items_seed[item_key].id)
  //   diary_data.value.items_seed[item_key].is_remove = 1;
  // else  
  // diary_data.value.items_seed.splice(item_key,1);      
  diary_data.value.items_seed[item_key].is_remove = 1
}

const chooseSeed = function(item){      
  diary_data.value.items_seed.push(item);      
}

const chooseWareting = function(id){
  diary_data.value.type_watering = id;
}

const chooseRoom = function(id){      
  diary_data.value.type_room = id;      
}
 

const cancel = function(){   
  var url = id.value == 'new' ? '/explore' : '/diaries/' + id.value;  
  if(id.value == 'new'){
    var url = '/explore';
  }else{
    var url = '/diaries/' + diary_data.value.link;
  }
  router.push({
    path: url
  }) 
}

const save = function(){

  isLoadingSave.value = true;

  // var form = getForm();

  // console.log(form)

  if(!diary_data.value.id || diary_data.value.id == 'new'){

    // form.id = null;

    // $api.postDiary(form)
    $api.postDiary(diary_data.value)
    .then((res) => {                        
      var data = res.data.value.data; 
      router.push('/diaries/' + data.link);
      $popup.success(t('create_diary_view_message_success'));
      $ga.addDiary();
      isLoadingSave.value = false;
    })
    .catch(function (error) { 
      isLoadingSave.value = false;
      $popup.error(t('create_diary_view_error_failed'));
    });

  }else{

    $api.putDiary(diary_data.value.id, diary_data.value)
    .then((res) => {                    
      var data = res.data.value.data; 
      console.log(res.data.value);
      router.push('/diaries/' + data.link);
      $popup.success(t('create_diary_view_message_success'));
      isLoadingSave.value = false;
    })
    .catch(function (error) { 
      console.log(error);
      $popup.error(t('create_diary_view_error_failed'));
      isLoadingSave.value = false;
    }); 

  }

}

const changedCover = function(uploading_id){  
  console.log('loading', uploading_id)
}

const uploadedCover = function(data){  
  diary_data.value.avatar_small = data;
}

// 

const loadData = async function(id) {   
  if(id == 'new'){
    return def.value;
  }  
  const response = await $api.getDiary(id)  
  return response;  
}


const { pending: is_loading,  data: diary_data } = await useLazyAsyncData('diary_data', async () => await loadData(id.value))


 
</script>

<style scoped>
 
h1,
h2,
h3{
  margin-top: 1.3rem;
  margin-bottom: 1rem;
}

.medium_used{
  color: #68ae3c;
} 
.warn {
  position: relative;
  min-height: 1em;
  margin: 1em 0em;
  background: #F8F8F9;
  padding: 1em 1.5em;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
  transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
  border-radius: 0.28571429rem;
  box-shadow: 0px 0px 0px 1px rgb(34 36 38 / 22%) inset, 0px 0px 0px 0px rgb(0 0 0 / 0%);
  background-color: #FFF8DB;
  color: #B58105;
  box-shadow: 0px 0px 0px 1px #b58105 inset, 0px 0px 0px 0px rgb(0 0 0 / 0%);
}
.items{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
    align-items: flex-start;
}
.head_diary{
  display: flex;
  align-items: center;
  gap:1rem;
}
.head_diary .inf{
  width: 100%;
}
.head_diary .ava{
  width: 180px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.head_diary .ava .img{
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 10px;
}
.clone{
  margin-bottom: 0.5rem;
}
.clone_btn{
  cursor: pointer;
}
</style>
